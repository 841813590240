<template>
    <div>
        <v-card outlined class="mt-n3" tile>
            <v-card-title class="pa-0 ma-0">
                <v-toolbar flat class="pt-2">
                    <div align="left">
                        <v-toolbar-title
                            class="
                                info--text
                                text-uppercase
                                font-weight-bold
                                text--darken-1
                                justify-center
                            "
                        >
                            <v-icon color="info">mdi-account</v-icon>
                            User Profile
                        </v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <div align="right">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="info"
                                    dark
                                    fab
                                    x-small
                                    class="text-capitalize my-auto ml-1"
                                    elevation="0"
                                    @click="completeOrganizationProfile"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ completeProfile ? 'Update' : 'Complete' }} Profile</span>
                        </v-tooltip>
                    </div>
                </v-toolbar>
            </v-card-title>

            <v-divider></v-divider>
            
            <v-card-text class="mb-0 pb-0">
                <v-form class="pt-3 pb-0">
                    <v-row>
                        <v-col>

                            <v-row>
                               <v-col>
                                   <v-text-field
                                        dense
                                        v-model="user.firstname"
                                        label="First Name"
                                        :error-messages="apiErrors.firstname"
                                        outlined
                                        :disabled="completeProfile"
                                    ></v-text-field>
                               </v-col>
                               <v-col>
                                    <v-text-field
                                        dense
                                        v-model="user.lastname"
                                        label="Last Name"
                                        :error-messages="apiErrors.lastname"
                                        outlined
                                        :disabled="completeProfile"
                                    ></v-text-field>
                               </v-col>
                           </v-row>

                            <v-text-field
                                dense
                                v-model="user.phone"
                                label="Phone"
                                :error-messages="apiErrors.phone"
                                outlined
                                :disabled="completeProfile"
                            >
                                <template v-slot:prepend-inner>        
                                    <v-icon 
                                        :color="user.phone_is_verified ? 'info' : 'grey'"
                                    >{{ user.phone_is_verified ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline '}}</v-icon> 
                                </template>
                                <template v-slot:append>        
                                    <v-icon 
                                        :color="user.share_phone ? 'info' : 'grey'"
                                        @click="user.share_phone"
                                    >mdi-share-variant</v-icon> 
                                </template>
                            </v-text-field>

                            <v-text-field
                                dense
                                v-model="user.email"
                                label="Email"
                                :error-messages="apiErrors.phone_number"
                                outlined
                                :disabled="completeProfile"
                            >
                                <template v-slot:prepend-inner>        
                                    <v-icon 
                                        :color="user.email_is_verified ? 'info' : 'grey'"
                                    >{{ user.email_is_verified ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline '}}</v-icon> 
                                </template>
                                <template v-slot:append>        
                                    <v-icon 
                                        :color="user.share_email ? 'info' : 'grey'"
                                        @click="user.share_email"
                                    >mdi-share-variant</v-icon> 
                                </template>
                            </v-text-field>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dense
                                        v-model="user.id_number"
                                        label="ID. Number"
                                        :error-messages="apiErrors.id_number"
                                        outlined
                                        :disabled="completeProfile"
                                    >
                                        <template v-slot:append>        
                                            <v-icon :color="user.share_id ? 'info' : 'grey'">mdi-share-variant</v-icon> 
                                        </template>
                                    </v-text-field>
                                </v-col>
                                
                            </v-row>

                            <v-text-field
                                dense
                                v-model="user.dob"
                                label="Date of Birth"
                                :error-messages="apiErrors.website"
                                outlined
                                :disabled="completeProfile"
                            ></v-text-field>

                        </v-col>
                        
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <v-row justify="center">
            <v-dialog
                v-model="showEditCompanyProfileModal"
                scrollable
                max-width="600px"
            >
                <v-card>

                    <v-card-title class="grey--text text-h5">
                        Profile
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text class="mb-0 pb-0">

                        <v-form class="pt-7 pb-0">
                            <v-row>
                                <v-col>

                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                    dense
                                                    v-model="user.firstname"
                                                    label="First Name"
                                                    :error-messages="apiErrors.firstname"
                                                    outlined
                                                ></v-text-field>
                                        </v-col>
                                        <v-col>
                                                <v-text-field
                                                    dense
                                                    v-model="user.lastname"
                                                    label="Last Name"
                                                    :error-messages="apiErrors.lastname"
                                                    outlined
                                                ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-text-field
                                        dense
                                        v-model="user.phone"
                                        label="Phone"
                                        :error-messages="apiErrors.phone"
                                        outlined
                                    >
                                        <template v-slot:prepend-inner>        
                                            <v-icon 
                                                :color="user.phone_is_verified ? 'info' : 'grey'"
                                            >{{ user.phone_is_verified ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline '}}</v-icon> 
                                        </template>
                                        <template v-slot:append>        
                                            <v-icon 
                                                :color="user.share_phone ? 'info' : 'grey'"
                                                @click="user.share_phone = !user.share_phone"
                                            >mdi-share-variant</v-icon> 
                                        </template>
                                    </v-text-field>

                                    <v-text-field
                                        dense
                                        v-model="user.email"
                                        label="Email"
                                        :error-messages="apiErrors.email"
                                        outlined
                                    >
                                        <template v-slot:prepend-inner>        
                                            <v-icon 
                                                :color="user.email_is_verified ? 'info' : 'grey'"
                                            >{{ user.email_is_verified ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline '}}</v-icon> 
                                        </template>
                                        <template v-slot:append>        
                                            <v-icon 
                                                :color="user.share_email ? 'info' : 'grey'"
                                                @click="user.share_email = !user.share_email"
                                            >mdi-share-variant</v-icon> 
                                        </template>
                                    </v-text-field>

                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                dense
                                                v-model="user.id_number"
                                                label="ID. Number"
                                                :error-messages="apiErrors.id_number"
                                                outlined
                                            >
                                                <template v-slot:append>        
                                                    <v-icon 
                                                        :color="user.share_id ? 'info' : 'grey'"
                                                        @click="user.share_id = !user.share_id"
                                                    >mdi-share-variant</v-icon> 
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        
                                    </v-row>

                                    <v-text-field
                                        dense
                                        v-model="user.dob"
                                        label="Date of Birth"
                                        :error-messages="apiErrors.dob"
                                        outlined
                                    ></v-text-field>

                                </v-col>
                                
                            </v-row>
                        </v-form>
                    </v-card-text>
                    
                    <v-divider></v-divider>

                    <v-card-actions>

                        <v-btn
                            color="red darken-1"
                            text
                            @click="closeModal"
                            class="text-capitalize mx-1"
                        >
                            Cancel
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                            color="green darken-1 white--text"
                            @click="updateUserProfile"
                            class="text-capitalize mx-1"
                        >
                            {{ completeProfile ? "Update" : "Complete"}}
                        </v-btn>
                        
                    </v-card-actions>

                </v-card>

            </v-dialog>
        </v-row>

    </div>
</template>
<script>
import OrganizationService from '../../services/organization.service';
import User from '../../models/user';
import UserService from '../../services/user.service';
export default {
    name : "CompanyProfile",
    data() {
        return {
            completeProfile : true,
            user : new User(),
            business_types : [],
            showEditCompanyProfileModal : false,
            file_name : 'company_logo',

            logoFileToBeUploaded : [],
            loadingUpload : false,
            apiErrors : [],
        }
    },
    computed: {
        userData() {
            return JSON.parse(localStorage.getItem("user"));
        },
    },
    created() {
        this.$nextTick(() => {
            // this.adminParentOrganization()
            this.populateUserProfile()
        });
    },
    methods : {

        populateUserProfile() {
            this.user.id = this.userData.id;
            this.user.firstname = this.userData.first_name;
            this.user.lastname = this.userData.last_name;
            this.user.username = this.userData.username;
            this.user.phone = this.userData.phone;
            this.user.email = this.userData.email;
            this.user.id_number = this.userData.id_number;
            this.user.dob = this.userData.dob;

            // Privacy
            this.user.share_id = this.userData.share_id;
            this.user.share_phone = this.userData.share_phone;
            this.user.share_email = this.userData.share_email;
            this.user.share_name = this.userData.share_name;
            this.user.share_photo = this.userData.share_photo;
            this.user.share_company = this.userData.share_company;

            // Verified
            this.user.phone_is_verified = this.userData.phone_is_verified
            this.user.email_is_verified = this.userData.email_is_verified
        },

        adminParentOrganization() 
        {
            OrganizationService.admin_parent_organization().then(
                success => {
                    if(success.data.data != null) {
                        console.log(success.data.data)
                        let data = success.data.data;
                        this.organization = success.data.data.attributes
                        this.organization.id = data.id
                    }
                },
                error => {
                    console.log(error)
                }
            )
        },

        completeOrganizationProfile()
        {
            this.showEditCompanyProfileModal = true;
        },

        updateUserProfile()
        {
            UserService.update(this.user).then(
                success => {
                    if(success.status == 200) {
                        let data = success.data.data;
                        this.organization = success.data.data.attributes
                        this.organization.id = data.id
                    }
                },
                error => {
                    console.log(error)
                    if (error.response.status == 422) {
                        
                        this.apiErrors = error.response.data.errors

                        console.log(error.response.data.errors)

                        this.$store.dispatch(
                        "alert/error",
                        error.response.data.message
                        );
                        this.loading = false;

                    }

                    this.$store.dispatch("alert/error", error.response.data.message);
                }
            );
        },

        closeModal()
        {
            this.showEditCompanyProfileModal = false;
            this.apiErrors = [];
        },

        uploadCompanyLogo()
        {

        }
    }
}
</script>